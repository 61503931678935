import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { FaHandSpock ,FaLocationArrow} from "react-icons/fa";
import SplitType from 'split-type';

 const Data = () => {
  const titleRef = useRef(null);
 
  const subtitleRef = useRef(null);
  const descriptionRef = useRef(null);
  const buttonRef = useRef(null);
  useEffect(() => {
  
    const myText = new SplitType('#my-text');
    console.log(myText);
    gsap.to('.char', {
      y: 0,
      stagger: 0.05,
      delay: 0.2,
      duration: .1
    });
    gsap.fromTo(
      [subtitleRef.current, descriptionRef.current,buttonRef.current ],
      { opacity: 0, y: 50 }, // Start from opacity 0 and 50 pixels below their final position
      { 
        opacity: 1, // Animate to full opacity
        y: 0, // Animate to final y position
        duration: 1, // Duration of the animation
        stagger: 0.5, // Delay between start of each animation
        ease: 'power1.out', // Use an easing function for a smoother animation
        delay: 1
      }
    );


  }, []);

 return(
    <div className="home__data">
        <h1 className="home__title"  ref={titleRef} ><span id='my-text'>Mizero Maxime <FaHandSpock className="hand"  /></span> 
        </h1>
        <h3 className="home__subtitle" ref={subtitleRef} >Full-stack Developer</h3>
        <p className="home__description" ref={descriptionRef} >
          I'm a Creative web developer with 6+ years of experience in crafting visually stunning and user-centric websites.
          proven track record to deliver high-quality work on time and within budget.
        </p>
        {/* I am a designer who is passionate about his art and uses technology to create functional art to help you realize your dreams. */}
         <a href="#contact" className="button button--flex opacity"  ref={buttonRef}>Say Hello <FaLocationArrow className="button-icon"/></a>
         </div>
 )
}
export default Data
