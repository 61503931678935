export const projectsData = [
  {
    id: 1,
    image:
      "https://i.pinimg.com/736x/5a/9a/8a/5a9a8a29b63fe9380484998a2576b8ab.jpg",
    title: "Digital Nite",
    category: "Web",
    url: "https://master--digitanite.netlify.app/",
  },
  {
    id: 2,
    image:
      "https://i.pinimg.com/736x/ae/84/a1/ae84a1a4961bba5dd0bf19dfde529050.jpg",
    title: "Urban Nite",
    category: "App",
    url: "https://master--urbanitehub.netlify.app/night-life",
  },
  {
    id: 3,
    image:
      "https://i.pinimg.com/564x/21/e3/89/21e3891afb530e79a0bbf7369b57578a.jpg",
    title: "Art site",
    category: "Web",
    url: "https://maxcastro1.github.io/artwebsite.io/",
  },
  {
    id: 4,
    image:
      "https://i.pinimg.com/564x/36/1c/ad/361cade62a60f80a78686845cbdd7640.jpg",
    title: "Photographer portofolio",
    category: "Web",
    url: "https://maxcastro1.github.io/photographerPorfolio.io/",
  },
  {
    id: 5,
    image:
      "https://i.pinimg.com/736x/3b/2d/20/3b2d20d380f2141f29383c1d20be18a1.jpg",
    title: "Hip Hop magazine",
    category: "Web",
    url: "https://maxcastro1.github.io/hip-hop-magazine.io/",
  },
  {
    id: 6,
    image:
      "https://i.pinimg.com/564x/de/37/8e/de378edc59da342f33ede518012c5e9a.jpg",
    title: "Animated scrollbar",
    category: "Design",
    url: "https://maxcastro1.github.io/designs.io/",
  },
  {
    id: 7,
    image:
      "https://i.pinimg.com/564x/59/06/2e/59062e7068a4f966ddd209c2064e7109.jpg",
    title: "Kubeza",
    category: "Design",
    url: "https://maxcastro1.github.io/new-connection.io/",
  },
  {
    id: 8,
    image:
      "https://i.pinimg.com/originals/e4/17/ea/e417eac7f325acc7d3aa160aa821293e.png",
    title: "Music App",
    category: "App",
    url: "https://music-fy-2-0.netlify.app/",
  },
  {
    id: 9,
    image:
      "https://i.pinimg.com/564x/b5/01/49/b50149ab6a80da4d05dc6f1fd7543854.jpg",
    title: "New times",
    category: "Design",
    url: "https://maxcastro1.github.io/new-times.io/",
  },
  {
    id: 10,
    image:
      "https://i.pinimg.com/564x/ba/8b/f6/ba8bf6c77ed373201712afe6cc6aa2e9.jpg",
    title: "Twitter clone",
    category: "App",
    url: "https://twiter-clone-nine.vercel.app/",
  },
  {
    id: 11,
    image:
      "https://i.pinimg.com/736x/22/e9/6a/22e96a586c5954309aebd181be2fa423.jpg",
    title: "AI Solutions",
    category: "web",
    url: " https://maxcastro1.github.io/AIsolutions.io/",
  },
  {
    id: 12,
    image:
      "https://i.pinimg.com/736x/77/e1/d7/77e1d7916ad30583343909c569e3d9d3.jpg",
    title: "Red",
    category: "Web",
    url: " https://maxcastro1.github.io/red/",
  },
  {
    id: 13,
    image:
      "https://i.pinimg.com/736x/41/c1/24/41c1246d7032bcf3b58331607afdf105.jpg",
    title: "Create Podcast",
    category: "Web",
    url: "https://maxcastro1.github.io/podcast.io/",
  },
  {
    id: 14,
    image:
      "https://i.pinimg.com/736x/bb/e2/01/bbe201ae97a53b383177d6bd14d68be8.jpg",
    title: "Podcast",
    category: "Web",
    url: " https://maxcastro1.github.io/podcast2.io/",
  },
  {
    id: 15,
    image:
      "https://i.pinimg.com/736x/9c/7c/11/9c7c118aa68e972e5858faa9f0422c74.jpg",
    title: "Patelino studio",
    category: "Web",
    url: "https://maxcastro1.github.io/patelino/",
  },
  {
    id: 16,
    image:
      "https://i.pinimg.com/736x/e5/ab/0a/e5ab0aedfa6320d67e466b4e2b2582d5.jpg",
    title: "Gym",
    category: "Web",
    url: "https://maxcastro1.github.io/gym-template.io/",
  },
  {
    id: 17,
    image:
      "https://i.pinimg.com/736x/41/b2/6d/41b26d148c36deccfa7d8515b8d021da.jpg",
    title: "Rwanda's Plate (restaurant)",
    category: "Web",
    url: "https://maxcastro1.github.io/Rwandasplate.io/",
  },
];
export const projectsNav = [
  {
    name: "All",
  },
  {
    name: "Web",
  },
  {
    name: "App",
  },
  {
    name: "Design",
  },
];
