import React, { useEffect, useRef,useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './service.css'
import { IoIosCheckmarkCircleOutline,IoIosArrowRoundForward,IoIosClose } from "react-icons/io";
import { FiEdit } from "react-icons/fi";
import { BsGrid1X2,BsCode } from "react-icons/bs";

gsap.registerPlugin(ScrollTrigger);
const Service = () =>{

    const [toggleState,setToggleState] = useState(0)

    const toggleTab = (index) =>{
        setToggleState(index)
    }
    const titleRef = useRef(null);
    const subtitleRef = useRef(null);
    const sectionRef = useRef(null);
    useEffect(() => {
        gsap.fromTo(
          [titleRef.current,sectionRef.current],
          {opacity: .2, y: 10,},
          {opacity: 1, y: 0, duration: 1, stagger: 0.5, ease: 'power1.out',
          scrollTrigger: {
            trigger: sectionRef.current,
            start: 'top 80%', // when the top of the trigger hits the bottom of the viewport
            end: 'top 40%', // when the bottom of the trigger hits the top of the viewport
            scrub: false,
            toggleActions: 'play none none none'
          }
        }
          
        );
      }, []);
    return(
        <section className="services section" id="services" ref={sectionRef}>
             <h2 className="section__title" ref={titleRef}>Services</h2>
            <span className="section__subtitle" ref={subtitleRef}>What i offer</span>

            <div className="services__container container grid">
                <div className="services__content ">
                    <div>
                        <BsGrid1X2 className="services__icon" />
                        <h3 className="services__title">Full-Stack   <br /> Web Development</h3>
                    </div>
                    <span className="services__button" onClick={()=>toggleTab(1)}>View more <IoIosArrowRoundForward className="service__button-icon"/></span>
                    <div className={toggleState === 1 ? "services__modal active-modal" : "services__modal"}>
                    <div className="services__modal-content">
                        <IoIosClose className = "servics__modal-close"  onClick={()=>toggleTab(0)}/>
                        <h3 className="services__modal-title">Full-Stack   <br /> Web Development</h3>
                            <p className="services__modal-description">
                                Service with more than 5 years of experience.
                                providing quality work to client and companies.
                            </p>
                            <ul className="services_modal-services  grids grid">
                                 <li className="services__modal-service">
                                 <IoIosCheckmarkCircleOutline className="services__icon" />
                                    {/* icon className="services__modal-icon" */}
                                    <p className="services__modal-info">
                                    Custom Web Application Development
                                    </p>
                                 </li>

                                 <li className="services__modal-service">
                                 <IoIosCheckmarkCircleOutline className="services__icon" />
                                    {/* icon className="services__modal-icon" */}
                                    <p className="services__modal-info">
                                    Responsive & User-Centric Web Experiences
                                    </p>
                                 </li> 

                                 <li className="services__modal-service">
                                 <IoIosCheckmarkCircleOutline className="services__icon" />
                                    {/* icon className="services__modal-icon" */}
                                    <p className="services__modal-info">
                                       Website Modernization & Maintenance
                                    </p>
                                 </li>

                            </ul>
                        </div>
                    </div>
                </div>

                <div className="services__content ">
                    <div>
                        <BsCode className="services__icon" />
                        <h3 className="services__title">User Experience(UX). <br /> User Interface(UI).</h3>
                    </div>
                    <span className="services__button"  onClick={()=>toggleTab(2)}>View more <IoIosArrowRoundForward className="service__button-icon"/></span>
                   
                    <div className={toggleState === 2 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                        <h3 className="services__modal-title">User Experience (UX) <br /> User Interface (UI) </h3>
                        <IoIosClose className = "servics__modal-close"   onClick={()=>toggleTab(0)}/>
                            <p className="services__modal-description">
                                Service with more than 3 years of experience.
                                providing quality work to client and companies.
                            </p>
                            <ul className="services_modal-services grids grid">
                                 <li className="services__modal-service">
                                 <IoIosCheckmarkCircleOutline className="services__icon" />
                                    {/* icon className="services__modal-icon" */}
                                    <p className="services__modal-info">
                                        I Conducting user research.
                                    </p>
                                 </li>

                                 <li className="services__modal-service">
                                 <IoIosCheckmarkCircleOutline className="services__icon" />
                                    {/* icon className="services__modal-icon" */}
                                    <p className="services__modal-info">
                                      I Determining the architecture info of a digital product
                                    </p>
                                 </li> 

                                 <li className="services__modal-service">
                                 <IoIosCheckmarkCircleOutline className="services__icon" />
                                    {/* icon className="services__modal-icon" */}
                                    <p className="services__modal-info">
                                        I conducting user testing.
                                    </p>
                                 </li>

                                 <li className="services__modal-service">
                                 <IoIosCheckmarkCircleOutline className="services__icon" />
                                    {/* icon className="services__modal-icon" */}
                                    <p className="services__modal-info">
                                     Designing user flows and wireframes.
                                    </p>
                                 </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="services__content ">
                    <div>
                        <FiEdit className="services__icon" />
                        <h3 className="services__title">Unified Content  <br />& Integration Services</h3>
                    </div>
                    <span className="services__button"  onClick={()=>toggleTab(3)}>View more <IoIosArrowRoundForward className="service__button-icon"/></span>
                    <div className={toggleState === 3 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                        <h3 className="services__modal-title">Unified Content  <br />& Integration Services</h3>
                        <IoIosClose className = "servics__modal-close"  onClick={()=>toggleTab(0)}/>
                            <p className="services__modal-description">
                            Content Management System (CMS) Integration.
                            </p>
                            <ul className="services_modal-services grids grid ">
                                 <li className="services__modal-service">
                                 <IoIosCheckmarkCircleOutline className="services__icon" />
                                    <p className="services__modal-info">
                                    I implement user-friendly CMS platforms.
                                    </p>
                                 </li>

                                 <li className="services__modal-service">
                                 <IoIosCheckmarkCircleOutline className="services__icon" />
                                    <p className="services__modal-info">
                                    I enable clients to effortlessly manage and update their website content.
                                    </p>
                                 </li> 


                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Service