import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './work.css'
import Works from './works'
gsap.registerPlugin(ScrollTrigger);


const Work = () => {

    const titleRef = useRef(null);
    const subtitleRef = useRef(null);
    const sectionRef = useRef(null);

    useEffect(() => {
        gsap.fromTo(
          [titleRef.current,sectionRef.current],
          {opacity: .2, y: 10,},
          {opacity: 1, y: 0, duration: 1, stagger: 0.5, ease: 'power1.out',
          scrollTrigger: {
            trigger: sectionRef.current,
            start: 'top 80%', // when the top of the trigger hits the bottom of the viewport
            end: 'top 40%', // when the bottom of the trigger hits the top of the viewport
            scrub: false,
            toggleActions: 'play none none none'
          }
        }
          
        );
      }, []);
    return(
        <section className='work section' id='portfolio' ref={sectionRef}>
            <h2 className='section__title' ref={titleRef}>Portfolio</h2>
            <span className='section__subtitle' ref={subtitleRef}>Most recent works </span>
            <Works/>
        </section>
    )
}
export default Work;