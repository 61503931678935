import React, { useRef, useState, useEffect } from 'react'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import emailjs from '@emailjs/browser';
import './contact.css'
import { IoIosArrowRoundForward } from "react-icons/io";
import { BiMailSend } from "react-icons/bi";
import { BsWhatsapp } from "react-icons/bs";
import { FaLocationArrow } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

gsap.registerPlugin(ScrollTrigger);

const Contact = () => {

  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const sectionRef = useRef(null);

  useEffect(() => {
      gsap.fromTo(
        [titleRef.current,sectionRef.current],
        {opacity: .2, y: 10,},
        {opacity: 1, y: 0, duration: 1, stagger: 0.5, ease: 'power1.out',
        scrollTrigger: {
          trigger: sectionRef.current,
          start: 'top 80%', // when the top of the trigger hits the bottom of the viewport
          end: 'top 40%', // when the bottom of the trigger hits the top of the viewport
          scrub: false,
          toggleActions: 'play none none none'
        }
      }
        
      );
    }, []);

  const form = useRef();


  const [nameData, setname] = useState("")
  const [emailData, setEmailData] = useState("")
  const [textData, setText] = useState("")
  const [errorData, setErrorData] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [textError, setTextError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const resetErrors = () => {

    setErrorData(false)
    setNameError(false)
    setTextError(false)
    setEmailError(false)
    return

  }
  const timed = () => {
    setname("")
    setEmailData("")
    setText("")
  }
  const sendEmail = (e) => {
    e.preventDefault();
    if (nameData && textData && emailData) {
      emailjs.sendForm('service_80ppj89', 'template_vzv2wfl', form.current, 'Ydsiv6MDT_rLqdQfh').then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          toast.success("Succesfully sent message  !", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          e.target.reset()
          timed()
        },
        (error) => {
          console.log('FAILED...', error);
          toast.error("There was an error sending your message.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        },
      );

      return
    }
    else {
      setErrorData(true)
      if (!nameData) {
        setNameError(true)
      }

      if (!textData) {
        setTextError(true)
      }
      if (!emailData) {
        setEmailError(true)
      }
      return
    }

  };


  return (
    <section className='contact section' id='contact' ref={sectionRef}>

      <ToastContainer />
      <h2 className='section__title' ref={titleRef}>Get in touch</h2>
      <span className='section__subtitle' ref={subtitleRef}>Contact Me</span>

      <div className='contact__container container grid'>
        <div className='contact__content'>
          <h3 className='contact__title'>Talk to me</h3>


          <div className='contact__info'>
            <div className='contact__card'>
              < BiMailSend className='contact__card-icon' />
              <h3 className='contact__card-title'>Email</h3>
              <span className='contact__card-data'>mizeromaxime00@gmail.com</span>
              <a href='mailto:mizeromaxime00@gmail.com' className='contact__button'>
                Email me <IoIosArrowRoundForward className='contact__button-icon' />
              </a>
            </div>

            <div className='contact__card'>
              <BsWhatsapp className='contact__card-icon' />
              <h3 className='contact__card-title'>Whatsapp</h3>
              <span className='contact__card-data'>+250-780-439-884</span>
              <a href="https://api.whatsapp.com/send?phone=+250780439884&text=I'm%20interested%20in%20your%20services" className='contact__button'>
                Send a text <IoIosArrowRoundForward className='contact__button-icon' />
              </a>
            </div>
            {/* 
//                     <div className='contact__card'> 
//                        <BiMessageAltDots className='contact__card-icon'/>
//                         <h3 className='contact__card-title'>Messenger</h3>
//                         <span className='contact__card-data'>user.fb123</span>
//                         <a href='/#' className='contact__button'>
//                             Write me <IoIosArrowRoundForward className='contact__button-icon'/>
//                         </a>
//                     </div> */}
          </div>
        </div>

        <div className='contact__content'>
          <h3 className='contact__title'>Tell me about your project</h3>

          <form className='contact__form' ref={form} onSubmit={sendEmail}>
            <div className='contact__form-div'>
              <label className='contact__form-tag'>Name</label>
              <input
                autoComplete='off'
                type='text'
                name='name'
                className={nameError ? 'error contact__form-input' : 'contact__form-input'}
                placeholder='Insert your name'
                value={nameData}
                onChange={((event) => { setname(event.target.value) })}
                onFocus={() => { resetErrors() }}
              />
            </div>
            <div className='contact__form-div'>
              <label className='contact__form-tag'>Email</label>
              <input
                autoComplete='off'
                type='email'
                name='email'
                className={emailError ? 'error contact__form-input' : 'contact__form-input'}
                placeholder='Insert your email'
                value={emailData}
                onChange={((event) => { setEmailData(event.target.value) })}
                onFocus={() => { resetErrors() }}
              />
            </div>
            <div className='contact__form-div'>
              <label className='contact__form-tag'>Project</label>
              <textarea
                autoComplete='off'
                name='project'
                cols="30"
                rows="10"
                className={textError ? 'error contact__form-input contact__form-area' : 'contact__form-input contact__form-area'}
                placeholder='Write your project'
                value={textData}
                onChange={((event) => { setText(event.target.value) })}
                onFocus={() => { resetErrors() }}
              ></textarea>
              {errorData ? <p className='error-message'>please fill the form correctly</p> : null}
              <button className="button button--flex button-form">
                Say Hello
                <FaLocationArrow className="button-icon" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}
export default Contact
