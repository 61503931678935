import React, { useState } from 'react';
import { IoIosArrowRoundForward } from "react-icons/io";

const WorksItems = ({item}) => {
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };
    const {image,title,url} = item
    return(
     <div className="work__card" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div className={`work_img-cont ${isHovered ? 'work_img-cont--hover' : ''}`}>
        <img src={image} alt="" className={`work__img ${isHovered ? 'work__img--hover' : ''}`} />
        </div>
        <div className={`work__lower-cont ${isHovered ? 'work__lower-cont--hover' : ''}`}>
        <h3 className={`work__title ${isHovered ? 'work__title--hover' : ''}`}>{title}</h3>
        <a href={url} target="_blank" rel="noreferrer" className={`work__button ${isHovered ? 'work__button--hover' : ''}`}>
            Demo <IoIosArrowRoundForward className="work__button-icon"/>
        </a>
        </div>
       

     </div>   
    )
}
export default WorksItems
