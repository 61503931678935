import React, { useEffect, useRef } from 'react';
import { FaInstagram, FaDribbble, FaGithub } from "react-icons/fa";
import gsap from 'gsap';
const Social = () => {
    const iconRef = useRef(null);
    const icon2Ref = useRef(null);
    const icon3Ref = useRef(null);

    useEffect(() => {
        gsap.fromTo(
            [iconRef.current, icon2Ref.current,icon3Ref.current ],
            { opacity: 0, y: 20 }, // Start from opacity 0 and 50 pixels below their final position
            { 
              opacity: 1, // Animate to full opacity
              y: 0, // Animate to final y position
              duration: 1, // Duration of the animation
              stagger: 0.5, // Delay between start of each animation
              ease: 'power1.out', // Use an easing function for a smoother animation
              delay: 3
            }
          );

    },[])
    return (
        <div className="home__social">
            <a href="https://www.instagram.com/_saint_zy/" target='_black' ref={iconRef}>
                <FaInstagram className="home_social-icon"  />
            </a>
            <a href="https://dribbble.com/saintzy"  target='_black' ref={icon2Ref}>
                <FaDribbble className="home_social-icon"/>
            </a>
            <a href="https://github.com/maxCastro1"  target='_black' ref={icon3Ref}>
                <FaGithub  className="home_social-icon"/>
            </a>
        </div>
    )
}
export default Social;
