import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Info from "./info";
import './about.css'
import { HiDocumentText  } from "react-icons/hi";
import pp from '../../images/profilePic.jpg'
import CV from '../../docs/Resume-Mizero-Maxime.pdf'

gsap.registerPlugin(ScrollTrigger);

const About = () => {
    const titleRef = useRef(null);
    const subtitleRef = useRef(null);
    const sectionRef = useRef(null);

    useEffect(() => {
        gsap.fromTo(
          [titleRef.current,sectionRef.current],
          {opacity: .2, y: 10,},
          {opacity: 1, y: 0, duration: 1, stagger: 0.5, ease: 'power1.out',
          scrollTrigger: {
            trigger: sectionRef.current,
            start: 'top 100%', // when the top of the trigger hits the bottom of the viewport
            end: 'top 20%', // when the bottom of the trigger hits the top of the viewport
            scrub: false,
            toggleActions: 'play none none none'
          }
        }
          
        );
      }, []);

    return (
            <section className="about section" id="about" ref={sectionRef}>
                <h2 className="section__title" ref={titleRef} >About Me</h2>
                <span className="section__subtitle" ref={subtitleRef} >Behind The Code</span>
                <div className="about__container container grid">
                    <img alt = 'profile' src={pp} className="about__img" />
                    <div className="about__data">
                        <Info />
                        <p className="about__description">
                        I am skilled in leading collaborative teams, solving problems, and ensuring customer satisfaction. I possess a deep understanding of both front-end and back-end web development, and I am proficient in a number of languages and frameworks.
                        </p>
                        {/* href={CV} after import */}
                        <a href={CV} className="button button--flex">Download Cv  <HiDocumentText  className="nav__icon"/></a>
                    </div>
                </div>
            </section>
    )
}

export default About
