
import {HiArrowSmDown } from "react-icons/hi";
import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';


const ScrollDown = () => {
     const buttonRef = useRef(null); 
     useEffect(() => {      
          gsap.fromTo(
               [buttonRef.current ],
               { opacity: 0, y: 50 }, // Start from opacity 0 and 50 pixels below their final position
               { 
                 opacity: 1, // Animate to full opacity
                 y: 0, // Animate to final y position
                 duration: 1, // Duration of the animation
                 stagger: 0.5, // Delay between start of each animation
                 ease: 'power1.out', // Use an easing function for a smoother animation
                 delay: 3
               }
             );

      },[])
     return(
     <div className="home__scroll" ref={buttonRef} >
           <div className="scroll__container">
            <div className="scroll__toggle"></div>
        </div>
        <a href='#about' className="home__scroll-name" >Scroll Down</a>
        <HiArrowSmDown className="home__scroll-arrow"/>
     </div>
       
     )

}
export default ScrollDown;